@import "../../style/variables.css"; 

.about-us-main-page-container p {
  margin-top: 0;
  font-size: 15px;
  color: var(--text-color-secondary);
  line-height:1.4 ;
}
.about-us-main-page-container h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0.5rem;
  color: var(--primary-color); 
}  
.about-h1-heading-style{
  letter-spacing: -1px;
}
.about-us-main-page-container ul {
  font-size: 15px; 
  line-height: 1.4;
  color: var(--text-color-secondary);
}

.content-heading{
  color: var(--surface-400) !important;
} 
.about-us-main-page-container .heading-bg-container{
  background: #a4e99945;
} 

.experience-text-bg{
  background: var(--primary-color);
} 
.experience-text-color{ 
  color: var( --primary-color-text) !important;
} 
.experience-text-h5{
margin: 0 !important;
} 
.experience-text-h1{
  margin: 13px !important;
}
.values-icon-color{
  color:var(--primary-color);
} 
.about-links-style{
  text-decoration: none;
   color: inherit;
}
.about-us-main-page-container ul {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-left: 2rem;
}
.aboutImg2{
  margin-top: 45%;
}
.about-contact-bg{
  background: var(--primary-color);
}
@media (max-width: 998px){
  .aboutImg2{
    margin-top: 0%;
  } }