@import "../../style/variables.css"; 

.main-container p {
  margin-top: 0;
  margin-bottom: 1rem; 
  font-size: 14px;
  }
.main-container h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0.5rem;
  color: var(--primary-color); 
} 

.main-container label {
  margin-top: 0;
  margin-bottom: 1rem;
} 
.main-container .heading-bg-container{
  background: #a4e99945;
} 
.main-container .contact-icon-container{
  background: var(--primary-color); 
} 
.main-container .contact-form-bg{
   background: rgb(243, 245, 239);
}