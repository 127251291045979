.custom-menubar .p-menubar-button {
  background: rgb(255, 254, 254);
}
.custom-menubar {
  width: 100%;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0%;
  background: #57bb5b;
  z-index: 99;
  font-family: "Poppins";
}

.custom-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-content
  .p-menuitem-link {
  background-color: #57bb5b !important;
}

/* menubar text */
.custom-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-content
  .p-menuitem-link
  .p-menuitem-text {
  color: #fff !important;
  font-weight: 400;
}
.custom-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-content
  .p-menuitem-link:hover
  .p-menuitem-text {
  color: #fff !important;
  font-weight: 600;
}

/* home icons */
.custom-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-content
  .p-menuitem-link
  .p-menuitem-icon {
  color: #fff !important;
  font-weight: 400;
}

/* submenu styles */
.custom-menubar
  .p-menuitem
  > .p-menuitem-content
  .p-menuitem-link:hover
  .p-menuitem-text {
  color: #57bb5b !important;
}

.custom-menubar .p-menuitem > .p-menuitem-content :hover .p-menuitem-icon {
  color: #57bb5b !important;
}

/* submenu icon */
.custom-menubar
  .p-menuitem
  > .p-menuitem-content
  .p-menuitem-link
  .p-submenu-icon {
  color: #fff;
}

.custom-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content {
  background: #57bb5b;
  border-radius: 0px;
}
