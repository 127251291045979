@import "../../style/variables.css"; 

.page-information-container h1{
  color:var(--primary-color);
} 
.page-information-container p{
  font-size: 14px;
} 
.page-info-subheading{
  color:var(--gray-800);
}