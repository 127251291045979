@import "../../style/variables.css"; 

.main-footer-container p {
  margin-top: 0;
  margin-bottom: 1rem; 
  color: var(--text-color-secondary); 
  font-size: 14px; 
}
.main-footer-container a { 
  margin-top: 0;
  margin-bottom: 0;  
}

.main-footer-container h1,
h2,
h3,
h5 {
  margin-bottom: 0.5rem;
  color: var(--primary-color); 
  font-size:20px; 
  font-weight: 600;
} 

.footer-text {
  color: var(--text-color-secondary);
  text-decoration: none; 
  font-size: 14px;
}
.footer-text:hover {
  color: var(--primary-color);
} 
.main-footer-container .icon-style{
  color:var(--primary-color);
  font-Weight: 500;
} 
.main-footer-container .updating-disclaimer{
  color: var(--secondary-color);
} 

.main-footer-container .footer-bhoomi-text{
  color: var(--primary-color);
} 
.footer-links-style{
   text-decoration: none;
    color: inherit;
}