@import './style/reset.css';

::-webkit-scrollbar {
  width: 0;
}

input::placeholder {
  opacity: 1;
}

textarea::placeholder {
  opacity: 1;
}

.no-outline:focus {
  outline: none !important;
  box-shadow: none !important;
}

.p-dialog-header-icons {
  position: absolute !important;
  padding-right: 5%;
  right: 0%;
}

@media (max-width: 360px) {
  .p-dialog-header-icons {
    margin-top: -4%;
  }
}

.p-password-input {
  width: 100% !important;
}

.p-password {
  display: block !important;
}

.custom-toggle-btn .p-button {
  background-color: transparent !important;
  padding: 7px !important;
}

.p-autocomplete .p-autocomplete-dropdown {
  display: none;
}

.p-button {
  font-size: 13px !important;
  font-weight: 400 !important;
}

.p-menuitem-content {
  font-size: 13px !important;
  font-weight: 500 !important;
  letter-spacing: 0.8px !important;
}

.p-menuitem-content .pi {
  font-size: 0.9rem;
}

.p-menuitem-text {
  font-size: 14px;
  text-wrap: nowrap;
}

.p-fieldset .p-fieldset-legend {
  padding: 0.68rem;
  font-size: 15px !important;
  font-weight: 500;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.field {
  margin-bottom: 0rem !important;
}

.field label {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.7px;
}

.p-inputtext {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.7px;
}

.p-autocomplete-dd .p-autocomplete-input {
  border-radius: 6px;
}

.p-dropdown-item {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.6px;
}

.p-autocomplete-panel .p-autocomplete-items {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.6px;
}

.react-tel-input .form-control {
  font-size: 13px !important;
  font-weight: 400 !important;
  letter-spacing: 0.7px !important;
  color: var(--text-color);
}

.p-inline-message.p-inline-message-error {
  font-size: 13px !important;
  font-weight: 400 !important;
  letter-spacing: 0.7px !important;
}


.progress-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.custom-spinner {
  border: 6px solid white;
  border-top: 6px solid var(--primary-color);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.distance-container {
  margin-top: 10px;
  background-color: var(--surface-b);
  padding: 1px;
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font: 400 11px Roboto, Arial, sans-serif;
}

.main-logo-heading {
  color: var(--primary-color);
}

.aside-width {
  width: calc(100vw - 14rem);

}

@media (max-width: 769px) {
  .aside-width {
    width: calc(100vw - 0.5rem);

  }
}